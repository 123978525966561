import { AUTHENTICATION_STORAGE_KEY } from '@cca-common/authentication';

export function migrateAuthentication() {
  // used to be part of the authentication code
  localStorage.removeItem('rebrandingModalAcknowledged');

  try {
    const oldAuthenticationStorage = localStorage.getItem('authentication');
    if (oldAuthenticationStorage) {
      const oldAuthData = JSON.parse(oldAuthenticationStorage as string);
      const newAuthData = {
        ...oldAuthData,
        _realUser: oldAuthData.realUser,
        _projectedUser: oldAuthData.projectedUser,
      };
      delete newAuthData.realUser;
      delete newAuthData.projectedUser;
      localStorage.setItem(
        AUTHENTICATION_STORAGE_KEY,
        JSON.stringify(newAuthData),
      );
    }
  } catch {
    console.warn('Could not migrate existing authentication data');
  } finally {
    localStorage.removeItem('authentication');
  }
}
