/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApplicationRef, enableProdMode } from '@angular/core';
import {
  enableDebugTools,
  bootstrapApplication,
} from '@angular/platform-browser';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app-config';
import { enableDevMenu } from '@cca-common/feature-dev';

if (environment.production) {
  enableProdMode();
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    smartlook: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hsConversationsSettings: any;

    enableDevMenu: () => void;
  }
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.smartlook = window?.smartlook || (() => {});
window.enableDevMenu = enableDevMenu;

try {
  if (environment.smartLookKey && window?.smartlook) {
    window?.smartlook('init', environment?.smartLookKey, {
      region: 'eu',
    });
  }
} catch {
  /* empty */
}

bootstrapApplication(AppComponent, appConfig)
  .then((moduleRef) => {
    if (!environment.production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    }
  })
  .catch((err) => console.error(err));
