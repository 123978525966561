import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import {
  AuthenticationConfigToken,
  AuthenticationFacade,
} from '@cca-common/authentication';
import { RouterFacade } from '@cca-common/cdk';

export const indexGuardFn: CanActivateFn = () => {
  const authentication = inject(AuthenticationFacade);
  const router = inject(RouterFacade);
  const authenticationConfig = inject(AuthenticationConfigToken);

  const user = authentication.user();
  if (!user) {
    authentication.logout();
    router.parseUrl(authenticationConfig.urlAfterLogout);
  }

  return router.navigate(['/home'], {
    replaceUrl: true,
  });
};
