import { removeOldBookingData } from './remove-old-booking-data';
import { migrateAuthentication } from './migrate-authentication';
import { migrateTranslocoIndexedDb } from './migrate-transloco-db';

const storageKey = 'STORAGE_VERSION' as const;

export function checkForMigrations(window: Window) {
  const localStorage = window?.localStorage;
  if (!localStorage) {
    return;
  }

  /**
   * Whenever we need to upgrade/modify/delete existing data, we should increase our storageVersion and add a new case below
   * to migrate a user's browser from version x --> current
   * by design these case's do not break, to fall to every migrate until the end ( noFallthroughCasesInSwitch is disabled for this reason )
   */
  const storageVersion = 3;

  /**
   * if we cannot find a STORAGE_VERSION assume it is 0,
   * we substract 1 to always make sure we execute the last migration, this is for the case that we have multiple tabs open
   * and after 1 tab has migrated, the other tab writes into localstorage. If we would not repeat the last migration we would still contain wrong data.
   */
  const oldStorageKey =
    Number(localStorage.getItem(storageKey) ?? 0).valueOf() - 1;

  switch (oldStorageKey) {
    case -1:
    case 0:
      removeOldBookingData();
    // falls through
    case 1:
      migrateAuthentication();
    // falls through
    case 2:
      migrateTranslocoIndexedDb();
  }

  if (oldStorageKey !== storageVersion) {
    localStorage.setItem(storageKey, `${storageVersion}`);
  }
}
