import {
  Configuration,
  BrowserCacheLocation,
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { PlatformEnvironmentToken } from '@cca-environment';
import { inject } from '@angular/core';

export function MSALInstanceFactory(): IPublicClientApplication {
  const environment = inject(PlatformEnvironmentToken);

  const msalConfig: Configuration = {
    auth: {
      clientId: environment.msalConfig.auth.clientID, // This is the ONLY mandatory field that you need to supply.
      authority: environment.msalConfig.auth.authority, // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
      postLogoutRedirectUri: '/', // Points to window.location.origin by default.
      clientCapabilities: ['CP1'], // This lets the resource server know that this client can handle claim challenges.
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    },
    system: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      // loggerOptions: {
      //   loggerCallback(logLevel: LogLevel, message: string) {
      //     console.log(message);
      //   },
      //   logLevel: LogLevel.Verbose,
      //   piiLoggingEnabled: false,
      // },
    },
  };

  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const environment = inject(PlatformEnvironmentToken);

  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();

  protectedResourceMap.set(
    environment.msalConfig.apiConfig.uri,
    environment.msalConfig.apiConfig.scopes,
  );

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

export const loginRequest = {
  scopes: ['user.read', 'mailboxsettings.read'],
};
